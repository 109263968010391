import Rails from "@rails/ujs";
import $ from 'jquery';
window.$ = $;

import 'bootstrap';
import '../scripts/msc_filters';
import '../scripts/cookie_warning';
import '../scripts/timeout_warning';

import '../pdfs/akt_privacy_notice.pdf'
import '../pdfs/mscaa_privacy_notice.pdf'

import { AjaxModal, ConfirmModal, VisibilityMap } from '@epigenesys/epi-js';

AjaxModal.start();
VisibilityMap.start();

ConfirmModal.options.cancelButtonClass = 'btn btn-default';

Rails.confirm = function (message, element) {
  return ConfirmModal.confirm(message, element);
}

Rails.start();
