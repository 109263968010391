import { Modal } from 'bootstrap';
import $ from 'jquery';

$(function () {
  if (!navigator.cookieEnabled) {
    const $template = `\
<div class="modal">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Please Enable Cookies to Continue</h1>
      </div>
      <div class="modal-body">
        <p>To access MSCA Assessment Suite, cookies must be enabled in your browser. No personal information will be stored in the cookie.</p>
        <p>Please enable cookies and refresh the page to continue.</p>
      </div>
    </div>
  </div>
</div>\
`;
    $($template).appendTo($('body'));
    new Modal($($template)[0], { backdrop: 'static', keyboard: false }).show();
    $('body').addClass('disabled').find(':focus').blur();
  }
});