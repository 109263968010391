import { Modal } from 'bootstrap';
import $ from 'jquery';

class TimeoutTimer {
  constructor(timeoutIn) {
    this.tick = this.tick.bind(this);
    this.timeoutAt = Date.now() + parseInt(timeoutIn);
    this.timer = setInterval(this.tick, 1000);
  }

  tick() {
    if (Date.now() > this.timeoutAt) {
      clearInterval(this.timer);
      return this.timedOut();
    }
  }

  timedOut() {
    const $template = `\
<div id="timeout-modal" class="modal fade" tabindex="-1" role="dialog" >
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Please refresh this page</h4>
      </div>
      <div class="modal-body">
        You have been timed out due to staying idle on this page for too long. Please refresh the page and try again.
      </div>
    </div>
  </div>
</div>\
`;
    $($template).appendTo($('body'));
    new Modal($($template)[0], { backdrop: 'static', keyboard: false }).show();
    $('body').addClass('disabled').find(':focus').blur();
  }
}
$(function () {
  if ($('input[name="authenticity_token"]').length > 0) {
    new TimeoutTimer($('body').data('timeout-in'));
  }
});
